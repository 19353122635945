<template>
  <zmAppPage :show-footer="false" :showHeaderLeft="true" title="登录" class="bc-white">
    <div class="login-box zm-flex is-column">
      <div class="login-main">
        <img class="img" :src="logo" />
        <!-- <van-divider>邮箱账号登录</van-divider> -->
        <div class="form-box">
          <authInput placeholder="请输入邮箱" v-model="form.account">
            <zm-icon icon-class="email" class="fs22 mr10" slot="left-icon"></zm-icon>
          </authInput>
          <authInput class="mt15" placeholder="请输入密码" v-model="form.password" type="password" :icon="icon.pwd" />
        </div>
        <zm-btn @click="handleLogin" class="mt40" size="large">
          登录
        </zm-btn>
        <!-- <div class="tr"> -->
        <div class="flex-b zm-flex is-justify-space-between">
          <div @click="onRegister" class=" txtBtn">
            立即注册
          </div>
          <div class="txtBtn" @click="handleForgetPwd">忘记密码</div>
        </div>
      </div>

      <login-footer @auth-suc="authSuc" v-model="agreeCheck" @check-agree="checkAgree"/>
      <forget-pwd v-if="forgetPwdVisible" v-model="forgetPwdVisible" @after="afterForget"> </forget-pwd>
      <Register v-if="showRegister" v-model="showRegister" @after="afterRegister"> </Register>
    </div>
  </zmAppPage>
</template>

<script>
import ForgetPwd from '@/pages/login/components/ForgetPwd';
import AuthInput from '@/pages/login/components/AuthInput';
import LoginFooter from '@/pages/login/components/Footer';
import Register from './components/register';
import logo from '@/assets/img/en-logo.png';
import apiUser from '@/api/src/apiUser';
import { mapActions } from 'vuex';
import * as path from '@/router/routes/routePath';
import { appSetToken } from '@/utils/src/utilsApp';
import {wxAuthorization} from '@/utils/src/utilsWeixin';
import * as auth from '@/utils/auth';
import config from '@/config';
import {STORAGE_FIRST_LOGIN} from '@/config/static';
export default {
  name: 'login',
  components: { AuthInput, LoginFooter, ForgetPwd, Register },
  data() {
    return {
      forgetPwdVisible: false,
      showRegister: false,
      logo,
      agreeCheck: false,
      form: {
        account: '',
        imei: '',
        password: ''
      },
      isLogin: false,
      icon: {
        signin: require('@/assets/img/icon-signin.png'),
        pwd: require('@/assets/img/icon_password.png')
      }
    };
  },
  computed: {
    redirect() {
      return this.$route.query.redirect;
    }
  },
  watch: {},
  created() {
    wxAuthorization().then((res) => {
      if (res) {
        this.afterRegister(res);
      }
    });
  },
  methods: {
    ...mapActions('app', ['appUpdateUserInfo']),
    onRegister() {
      this.showRegister = true;
    },
    async authSuc(wxUserInfo) {
      let userInfo = wxUserInfo || auth.loc.get('wxUserInfo', {});
      let appInfo = auth.loc.get('appInfo', {});
      let res = await apiUser.saveUserInfo({ ...userInfo, appInfoId: appInfo.id, imei: auth.getImei() });
      this.afterRegister(res);
    },
    async handleLogin() {
      if (!this.form.account) {
        this.$msg.showMsgFail('请输入正确的邮箱!');
        return;
      }
      if (!this.form.password) {
        this.$msg.showMsgFail('密码不能为空!');
        return;
      }
      if (!this.agreeCheck) {
        this.checkAgree();
        return;
      }
      let appInfo = auth.loc.get('appInfo', {});
      let res = await apiUser.login({ ...this.form, imei: auth.getImei(), appInfoId: appInfo.id });
      this.afterRegister(res);
      // await apiUser.getInfo
    },
    handleForgetPwd() {
      this.forgetPwdVisible = true;
    },
    updatePwdSuccess() {},
    checkAgree() {
      !this.agreeCheck && this.$msg.showMsgFail('请同意底部隐私协议!');
    },
    afterRegister(res) {
      appSetToken(res.accessToken);
      this.appUpdateUserInfo(res);
      this.$msg.showMsgSuccess('登录成功!');
       if (!res.user.account && res.accessToken) {
        const firstLogin = auth.loc.get(STORAGE_FIRST_LOGIN);
        if (!firstLogin) {
          this.$dialog
            .alert({
              title: '友情提示',
              message: '未绑定账号,请前往"我的"页面绑定账号，以便在其他手机登录',
              confirmButtonText: '我知道了'
            })
            .then(() => {
              auth.loc.set(STORAGE_FIRST_LOGIN, '1');
            });
        }
      }
      if (this.redirect) {
        this.$router.replace(this.redirect);
      } else {
        this.$router.replace({
          path: path.PATH_HOME
        });
      }
    },
    afterForget(email) {
      this.$msg.alert({
        message: `密码已发送到邮箱,请前往邮箱${email}查看!`
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.login-box {
  min-height: 6rem;
  // height: 100%;

  // overflow: hidden;
  .login-main {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    flex:1;
  }
  .title {
    font-size: 0.48rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  box-sizing: border-box;
  width: 100%;
  background: #fff;
  position: relative;
  .img {
    padding-top: 0.8rem;
    width: 3.4rem;
    // height: 1.8rem;
    display: block;
    margin: 0 auto;
  }

  .form-box {
    font-size: 0.32rem;
    margin-top: 0.8rem;
  }

  .txtBtn {
    color: $--color-primary;
    font-size: 0.26rem;
    margin-top: 0.3rem;
  }
}
</style>
