<!-- loginFooter 登录底部 -->
<template>
  <div class="loginFooter">
      <van-divider>微信授权登录</van-divider>
    <login-type class="mb5" @auth-suc="$emit('auth-suc')" :agree-check="value" @check-agree="$emit('check-agree')"/>
    <div class="txt tc">
      <div class="vm">
        <van-checkbox :value="value" @input="changeCheck">
          <span>同意{{appTitle}}科技</span>
        </van-checkbox>
      </div>
      <span @click="userAgree('2')" class="text-primary">《隐私协议》</span>
    </div>
    <user-agree v-model="show" :title="agreeTitle" />
  </div>
</template>

<script>
import loginType from './loginType'; // 登录类型
import userAgree from './userAgree';
import config from '@/config';
export default {
  name: 'LoginFooter',
  components: { userAgree, loginType },
  // components: { userAgree },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show: false,
      agreeTitle: '',
      agreeInfo: {},
      appTitle: config.appTitle,
      agreeCheck: false
    };
  },
  computed: {
  },
  created() {
    // this.init();
  },
  methods: {
    // 用户协议
    userAgree() {
      this.agreeTitle = '隐私协议';
      this.show = true;
    },
    changeCheck(v) {
      this.$emit('input',v);
    }
  }
};
</script>
<style lang="scss" scoped>
.vm {
  display: inline-block;
  vertical-align: middle;
}
.loginFooter {
  font-size: 0.24rem;
  text-align: center;
  box-sizing: border-box;
  color: $--color-text-regular;
  padding-bottom: 0.45rem;
  // position: absolute;
  // bottom: 0.45rem;
  // width: 100%;
  // left: 0;

  .txt {
    margin-top: 0.35rem;
  }
  .main-txt {
    color: $--color-primary;
  }
}
</style>
