<!-- authInput -->
<template>
  <div>
    <van-field v-bind="$attrs" v-on="$listeners" class="authInput" v-model="Value">
      <slot name="left-icon" slot="left-icon" class="img-icon">
        <img  class="img-icon" :src="icon" />
      </slot>
      <slot slot="right-icon" name="right-icon" />
    </van-field>
  </div>
</template>

<script>
export default {
  name: 'AuthInput',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    icon: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    Value: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    // 初始化方法
    init() {}
  }
};
</script>
<style lang="scss" scoped>
.img-icon {
  width: 0.44rem;
  height: 0.44rem;
  display: block;
  margin-right: 0.2rem;
}
.authInput {
  border-bottom: 1px solid #eee;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
