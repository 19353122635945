<template>
  <VanDialog
    :value="value"
    show-cancel-button
    :close-on-click-overlay="true"
    @click-overlay="close"
    :before-close="submit"
    @cancel="close"
  >
    <div class="pt20 plr20 pb20 ">
      <div class="tc fc-6 mb20">
        请输入您注册时的邮箱,找回密码
      </div>
      <div class="input-box">
        <van-field label="" t v-model.trim="email" placeholder="请输入邮箱" />
      </div>
    </div>
  </VanDialog>
</template>
<script>
import apiUser from '@/api/src/apiUser';
import { emailReg } from '@/utils/src/validateRules';
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: ''
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit('input', false);
    },
    async submit(action, done) {
      if (action === 'confirm') {
        if (!emailReg.test(this.email) || !this.email) {
          this.$msg.showMsg('请输入正确的邮箱!');
          return done(false);
        }
        apiUser
          .forgetPassword({ email: this.email })
          .then(() => {
            this.$emit('after', this.email);
            done();
            this.close();
          })
          .catch(_ => {
            done(false);
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.input-box {
  border: 1px solid #eee;
  border-radius: 0.1rem;
}
</style>
